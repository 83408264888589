<template>
  <router-link :to="{ name: 'ChartsUsersTariffsGrouped' }">
    <doughnut title="Tariffs" :apiData="latest" />
  </router-link>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import Doughnut from '@/components/Doughnut'

export default {
  name: 'UsersTariffsGroupedLatest',
  components: {
    Doughnut,
  },
  methods: {
    ...mapActions('users/tariffsGrouped', ['getLatest']),
  },
  computed: {
    ...mapState('users/tariffsGrouped', ['latest']),
  },
  created() {
    this.getLatest()
  },
}
</script>
