<template>
  <div>
    <div class="error" v-if="apiData.error" />
    <div v-else>
      <custom-chart-wrapper
        v-show="hasData"
        :title="title"
        :apiData="apiData"
        @filtersChanged="onFiltersChanged"
        :hiddenDatasets="hiddenDatasets"
      />
      <div class="skeleton" v-show="!hasData" />
    </div>
  </div>
</template>

<script>
import CustomChartWrapper from '@/components/CustomChartWrapper'

export default {
  name: 'Chart',
  components: {
    CustomChartWrapper,
  },
  props: {
    apiData: Object,
    hiddenDatasets: Array,
    title: String,
  },
  computed: {
    hasData: function () {
      return Object.keys(this.apiData).length > 0
    },
    total: function () {
      // eslint-disable-next-line no-unused-vars
      return this.apiData.total || Object.entries(this.apiData).reduce((acc, [key, value]) => acc + value, 0)
    },
  },
  methods: {
    onFiltersChanged(filters) {
      this.$emit('filtersChanged', filters)
    },
  },
}
</script>

<style lang="sass" scoped>
.error,
.skeleton
  display: flex
  width: 160px
  height: 160px
  margin: 20px
  border-radius: 50%

.error
  background-color: brown

.skeleton
  animation: shine 1.6s infinite linear
  background-image: linear-gradient(90deg, #cecece 20px, #d6d6d6 50px, #cecece 80px)
  background-size: 300px

@keyframes shine
  0%
    background-position: -80px
  40%, 100%
    background-position: 150px
</style>
