<template>
  <div class="date-filter">
    <action-list>
      <action>
        <date-picker v-model="settings.dateRange" is-range :max-date="new Date()" :columns="2">
          <template v-slot="{ togglePopover }">
            <span @click="togglePopover">Custom</span>
          </template>
        </date-picker>
      </action>
      <action @click="setRange(3)">Last 3 Days</action>
      <action @click="setRange(7)">Last Week</action>
      <action @click="setRange(30)">Last Month</action>
      <action @click="setRange(365)">Last Year</action>
    </action-list>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import moment from 'moment'

import Action from '@/components/Action'
import ActionList from '@/components/ActionList'

export default {
  name: 'DatesFilter',
  components: {
    Action,
    ActionList,
    DatePicker,
  },
  computed: {
    ...mapState('user', ['settings']),
  },
  created() {},
  methods: {
    ...mapActions('user', ['setSettings']),
    setRange(days) {
      this.setSettings({
        dateRange: {
          start: moment()
            .subtract(days - 1, 'days')
            .toDate(),
          end: new Date(),
        },
      })
    },
  },
}
</script>
