<template>
  <button class="action" :class="{ active: isActive }" @click="e => $emit('click', e)">
    <slot />
  </button>
</template>

<script>
export default {
  name: 'Action',
  props: {
    isActive: Boolean,
  },
}
</script>

<style lang="sass" scoped>
.action
  outline: none
  cursor: pointer
  -webkit-appearance: none
  border: none
  border-top: 1px solid #0d87ff
  border-bottom: 1px solid #0d87ff
  color: #0d87ff
  background-color: transparent
  font-size: 14px
  line-height: 22px
  padding: 4px 12px
  transition: background .2s
  border: 1px solid #0d87ff

  &.active
    color: #fff
    background-color: #0d87ff
</style>
