<template>
  <div class="action-list">
    <div class="title" v-if="title" @click="isCollapsed = !isCollapsed">{{ title }}</div>
    <div class="actions" v-show="!isCollapsed">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ActionList',
  props: {
    title: String,
    collapsed: Boolean,
  },
  data() {
    return {
      isCollapsed: false,
    }
  },
  created() {
    this.isCollapsed = this.collapsed
  },
}
</script>

<style lang="sass" scoped>
.action-list
  display: flex
  width: 100%
  flex-direction: column

  .title
    text-align: center
    font-size: 18px
    padding: 20px
    cursor: pointer

  .actions
    display: flex

    .action
      border-left: none
      border-right: none

      &+.action
        border-left: 1px solid #0d87ff

      &:first-of-type
        border-left: 1px solid #0d87ff
        border-top-left-radius: 2px
        border-bottom-left-radius: 2px

      &:last-of-type
        border-right: 1px solid #0d87ff
        border-top-right-radius: 2px
        border-bottom-right-radius: 2px
</style>
