<template>
  <div class="dateset-filter">
    <action-list>
      <action
        v-for="dataset of datasets"
        :key="dataset"
        @click="onDatasetClick(dataset)"
        :isActive="isDatasetActive(dataset)"
      >
        {{ dataset }}
      </action>
    </action-list>
  </div>
</template>

<script>
import Action from '@/components/Action'
import ActionList from '@/components/ActionList'

export default {
  name: 'DatesetFilter',
  components: {
    Action,
    ActionList,
  },
  props: {
    apiData: Object,
    hiddenDatasets: Array,
  },
  data() {
    return {
      unselectedDatasets: [],
    }
  },
  computed: {
    datasets() {
      return Object.keys(Object.entries(this.apiData)[0]?.[1] || {}).filter(
        key => !['total', ...(this.hiddenDatasets || [])].includes(key),
      )
    },
  },
  methods: {
    onDatasetClick(dataset) {
      if (!this.unselectedDatasets.includes(dataset)) {
        this.unselectedDatasets.push(dataset)
      } else {
        this.unselectedDatasets = this.unselectedDatasets.filter(item => item !== dataset)
      }
      const selectedDatasets = this.datasets.filter(dataset => !this.unselectedDatasets.includes(dataset))
      this.$emit('datasetChange', { selected: selectedDatasets, unselected: this.unselectedDatasets })
    },
    isDatasetActive(dataset) {
      return !this.unselectedDatasets.includes(dataset)
    },
  },
}
</script>
