<template>
  <router-link :to="{ name: 'ChartsUsersPaidTariffs' }">
    <doughnut title="Paid Tariffs" :apiData="filteredLatest" />
  </router-link>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import Doughnut from '@/components/Doughnut'

export default {
  name: 'UsersPaidTariffsLatest',
  components: {
    Doughnut,
  },
  methods: {
    ...mapActions('users/tariffs', ['getLatest']),
  },
  computed: {
    ...mapState('users/tariffs', ['latest']),
    filteredLatest: function () {
      // eslint-disable-next-line no-unused-vars
      const { free, nulll, ...rest } = this.latest
      return rest
    },
  },
  async created() {
    this.getLatest()
  },
}
</script>
