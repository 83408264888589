<template>
  <page title="Projects Tariffs">
    <projects-tariffs-all />
  </page>
</template>

<script>
import ProjectsTariffsAll from '@/charts/Projects/Tariffs/All'

export default {
  name: 'ChartsProjectsTariffs',
  components: {
    ProjectsTariffsAll,
  },
}
</script>
