<template>
  <page title="Users">
    <div class="charts-users">
      <users-active-latest class="chart" />
      <users-tariffs-latest class="chart" />
      <users-tariffs-grouped-latest class="chart" />
      <users-paid-tariffs-latest class="chart" />
    </div>
  </page>
</template>

<script>
import UsersActiveLatest from '@/charts/Users/Active/Latest'
import UsersPaidTariffsLatest from '@/charts/Users/PaidTariffs/Latest'
import UsersTariffsLatest from '@/charts/Users/Tariffs/Latest'
import UsersTariffsGroupedLatest from '@/charts/Users/TariffsGrouped/Latest'

export default {
  name: 'ChartsUsers',
  components: {
    UsersActiveLatest,
    UsersPaidTariffsLatest,
    UsersTariffsLatest,
    UsersTariffsGroupedLatest,
  },
}
</script>

<style lang="sass" scoped>
.charts-users
  display: flex
  width: 100%
</style>
