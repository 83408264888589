<template>
  <doughnut title="Median" :apiData="latest" />
</template>

<script>
import { mapActions, mapState } from 'vuex'

import Doughnut from '@/components/Doughnut'

export default {
  name: 'CJMMedianLatest',
  components: {
    Doughnut,
  },
  methods: {
    ...mapActions('cjm/median', ['getLatest']),
  },
  computed: {
    ...mapState('cjm/median', ['latest']),
  },
  async created() {
    this.getLatest()
  },
}
</script>
