<script>
import { Doughnut } from 'vue-chartjs'
import 'chartjs-plugin-labels'

export default {
  extends: Doughnut,
  props: {
    chartData: Object,
    title: String,
    total: Number,
  },
  computed: {
    options: function () {
      const { total } = this
      let title = this.title
      if (total) {
        title += ` (${total})`
      }

      return {
        responsive: false,
        elements: {
          arc: {
            borderColor: 'rgba(0, 0, 0, .2)',
          },
        },
        title: { display: true, text: title },
        legend: {
          display: false,
          position: 'right',
        },
        plugins: {
          labels: {
            render: ({ label, percentage }) => {
              return `${label} ${percentage}%`
            },
            fontColor: 'white',
            textShadow: true,
            shadowColor: 'rgba(0,0,0,1)',
          },
        },
      }
    },
  },

  mounted() {
    this.renderChart(this.chartData, this.options)
  },
}
</script>
