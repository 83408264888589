<template>
  <router-link :to="{ name: 'ChartsProjectsTariffs' }">
    <doughnut title="Tariffs" :apiData="latest" />
  </router-link>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import Doughnut from '@/components/Doughnut'

export default {
  name: 'ProjectsTariffsLatest',
  components: {
    Doughnut,
  },
  methods: {
    ...mapActions('projects/tariffs', ['getLatest']),
  },
  computed: {
    ...mapState('projects/tariffs', ['latest']),
  },
  async created() {
    this.getLatest()
  },
}
</script>
