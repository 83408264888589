<template>
  <page title="Users Activity">
    <users-active-all />
  </page>
</template>

<script>
import UsersActiveAll from '@/charts/Users/Active/All'

export default {
  name: 'ChartsUsersActive',
  components: {
    UsersActiveAll,
  },
}
</script>
