<template>
  <page title="Users on Paid Tariffs">
    <users-paid-tariffs-all />
  </page>
</template>

<script>
import UsersPaidTariffsAll from '@/charts/Users/PaidTariffs/All'

export default {
  name: 'ChartsUsersPaidTariffs',
  components: {
    UsersPaidTariffsAll,
  },
}
</script>
