<template>
  <chart title="Tariffs" @filtersChanged="update" :apiData="all" :hiddenDatasets="hiddenDatasets" />
</template>

<script>
import { mapActions, mapState } from 'vuex'

import Chart from '@/components/Chart'

export default {
  name: 'UsersTariffsGroupedAll',
  components: {
    Chart,
  },
  data() {
    return {
      hiddenDatasets: [],
    }
  },
  computed: {
    ...mapState('users/tariffsGrouped', ['all']),
  },
  methods: {
    ...mapActions('users/tariffsGrouped', ['getAll']),
    update({ startDate, endDate }) {
      this.getAll({ startDate, endDate })
    },
  },
}
</script>
