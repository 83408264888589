<template>
  <chart title="Active Projects" @filtersChanged="update" :apiData="filteredAll" />
</template>

<script>
import { mapActions, mapState } from 'vuex'

import Chart from '@/components/Chart'

export default {
  name: 'ProjectsActiveAll',
  components: {
    Chart,
  },
  computed: {
    ...mapState('projects/active', ['all']),
    filteredAll() {
      const filtered = { ...this.all }
      Object.keys(filtered).forEach(key => {
        delete filtered[key].activeAnd
        delete filtered[key].inactiveAnd
        delete filtered[key].archived
        delete filtered[key].archivedAnd
        delete filtered[key].unarchived
        delete filtered[key].unarchivedAnd
      })
      return filtered
    },
  },
  methods: {
    ...mapActions('projects/active', ['getAll']),
    update({ startDate, endDate }) {
      this.getAll({ startDate, endDate })
    },
  },
}
</script>
