<template>
  <page title="Projects Activity">
    <projects-active-all />
  </page>
</template>

<script>
import ProjectsActiveAll from '@/charts/Projects/Active/All'

export default {
  name: 'ChartsProjectsActive',
  components: {
    ProjectsActiveAll,
  },
}
</script>
