<template>
  <doughnut title="Archived" :apiData="filteredLatest" />
</template>

<script>
import { mapActions, mapState } from 'vuex'

import Doughnut from '@/components/Doughnut'

export default {
  name: 'ProjectsActiveLatest',
  components: {
    Doughnut,
  },
  methods: {
    ...mapActions('projects/active', ['getLatest']),
  },
  computed: {
    ...mapState('projects/active', ['latest']),
    filteredLatest: function () {
      // eslint-disable-next-line no-unused-vars
      const { active, inactive, ...rest } = this.latest
      return rest
    },
  },
  async created() {
    this.getLatest()
  },
}
</script>
