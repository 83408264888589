<template>
  <page title="Projects">
    <div class="charts-projects">
      <projects-active-latest class="chart" />
      <projects-archived-latest class="chart" />
      <projects-tariffs-latest class="chart" />
    </div>
  </page>
</template>

<script>
import ProjectsActiveLatest from '@/charts/Projects/Active/Latest'
import ProjectsArchivedLatest from '@/charts/Projects/Archived/Latest'
import ProjectsTariffsLatest from '@/charts/Projects/Tariffs/Latest'

export default {
  name: 'ChartsProjects',
  components: {
    ProjectsActiveLatest,
    ProjectsArchivedLatest,
    ProjectsTariffsLatest,
  },
}
</script>

<style lang="sass" scoped>
.charts-projects
  display: flex
  width: 100%
</style>
