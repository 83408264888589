const moment = require('moment')

module.exports = (startDate, endDate) => {
  const dates = []
  const dateRegex = new RegExp(/\d{4}-\d{2}-\d{2}/)

  if (startDate && !startDate.match(dateRegex)) {
    throw new Error('Could not parse startDate')
  }

  if (endDate && !endDate.match(dateRegex)) {
    throw new Error('Could not parse endDate')
  }

  const startDateMoment = moment(startDate)
  const endDateMoment = moment(endDate)
  const dateIterator = startDateMoment.clone()

  const diffDays = endDateMoment.diff(startDateMoment, 'days')

  if (diffDays < 0) {
    throw new Error('Start date can not be later than end date')
  }

  for (let i = 0; i < diffDays + 1; i++) {
    dates.push(dateIterator.format('YYYY:MM:DD'))
    dateIterator.add(1, 'day')
  }

  return dates
}
