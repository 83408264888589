<template>
  <div class="chart">
    <dates-filter />
    <dataset-filter :apiData="apiData" @datasetChange="onDatasetFilterChange" :hiddenDatasets="hiddenDatasets" />
    <custom-chart :title="title" :chartData="chartData" :height="400" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'

import CustomChart from '@/components/CustomChart'
import DatasetFilter from '@/components/DatasetFilter'
import DatesFilter from '@/components/DateFilter'

import getDatesBetween from '@/utils/getDatesBetween'
import toLineChartData from '@/utils/toLineChartData'

export default {
  name: 'CustomChartWrapper',
  components: {
    CustomChart,
    DatasetFilter,
    DatesFilter,
  },
  props: {
    apiData: Object,
    hiddenDatasets: Array,
    title: String,
  },
  data() {
    return {
      unselectedDatasets: [],
    }
  },
  computed: {
    ...mapState('user', ['settings']),
    startDate() {
      return this.formatDate(this.settings.dateRange.start)
    },
    endDate() {
      return this.formatDate(this.settings.dateRange.end)
    },
    allDates() {
      return getDatesBetween(this.startDate, this.endDate)
    },
    allInDateRange() {
      const filteredObject = {}
      this.allDates.forEach(date => {
        if (!this.apiData[date]) {
          return
        }
        filteredObject[date] = this.apiData[date] || {}
      })
      return { ...filteredObject }
    },
    chartData: function () {
      return toLineChartData(this.allInDateRange, { hiddenDatasets: this.unselectedDatasets })
    },
  },
  created() {
    this.update()
  },
  methods: {
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD')
    },
    update() {
      this.$emit('filtersChanged', { startDate: this.startDate, endDate: this.endDate })
    },
    onDatasetFilterChange({ unselected }) {
      this.unselectedDatasets = unselected
    },
  },
  watch: {
    startDate() {
      this.update()
    },
    endDate() {
      this.update()
    },
  },
}
</script>

<style lang="sass" scoped>
.chart
  position: relative
  width: 100%
  height: 100%
</style>
