const randomNum = () => Math.floor(Math.random() * (235 - 52 + 1) + 52)

const randomRGB = () => `rgb(${randomNum()}, ${randomNum()}, ${randomNum()})`

export default data => {
  const labels = []
  const datasets = [{ data: [], backgroundColor: [] }]
  Object.entries(data).forEach(([key, value]) => {
    if (key === 'total' || typeof value !== 'number') {
      return
    }
    labels.push(key)
    datasets[0].data.push(value)
    datasets[0].backgroundColor.push(randomRGB())
  })

  return {
    labels,
    datasets,
  }
}
