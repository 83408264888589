<script>
import { Line } from 'vue-chartjs'

export default {
  extends: Line,
  props: {
    chartData: Object,
    title: String,
  },
  watch: {
    chartData() {
      this.renderChart(this.chartData, this.options)
    },
  },
  computed: {
    options: function () {
      return {
        responsive: true,
        maintainAspectRatio: false,
        title: { display: true, text: this.title },
      }
    },
  },

  mounted() {
    this.renderChart(this.chartData, this.options)
  },
}
</script>
