<template>
  <page title="Tools">
    <div class="charts-tools">
      <h1>CJM</h1>
      <c-j-m-median-latest class="chart" />
      <c-j-m-tariffs-latest class="chart" />
    </div>
  </page>
</template>

<script>
import CJMMedianLatest from '@/charts/CJM/Median/Latest'
import CJMTariffsLatest from '@/charts/CJM/Tariffs/Latest'

export default {
  name: 'ChartsTools',
  components: {
    CJMMedianLatest,
    CJMTariffsLatest,
  },
}
</script>

<style lang="sass" scoped>
.charts-tools
  display: flex
  width: 100%
</style>
