<template>
  <div>
    <custom-doughnut-chart
      v-if="total"
      :title="title"
      :total="total"
      :chartData="chartData"
      :width="200"
      :height="200"
    />
  </div>
</template>

<script>
import CustomDoughnutChart from '@/components/CustomDoughnutChart'

import toChartData from '@/utils/toChartData'

export default {
  name: 'CustomDoughnutChartWrapper',
  components: {
    CustomDoughnutChart,
  },
  props: {
    apiData: Object,
    title: String,
    total: Number,
  },
  computed: {
    chartData: function () {
      return toChartData(this.apiData)
    },
  },
}
</script>
