<template>
  <page title="Users Tariffs">
    <users-tariffs-grouped-all />
  </page>
</template>

<script>
import UsersTariffsGroupedAll from '@/charts/Users/TariffsGrouped/All'

export default {
  name: 'ChartsUsersTariffsGrouped',
  components: {
    UsersTariffsGroupedAll,
  },
}
</script>
