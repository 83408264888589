const randomNum = () => Math.floor(Math.random() * (235 - 52 + 1) + 52)

const randomRGB = () => `rgb(${randomNum()}, ${randomNum()}, ${randomNum()})`

// TODO: refactor
export default (data, { hiddenDatasets }) => {
  let labels = []
  const datasetsObj = {}
  Object.entries(data).forEach(([key, value]) => {
    labels.push(key)
    Object.entries(value).forEach(([key, value]) => {
      if (!datasetsObj[key]) {
        datasetsObj[key] = {
          borderColor: randomRGB(),
          hidden: hiddenDatasets?.includes(key),
          label: key,
          data: [],
        }
      }
      datasetsObj[key].data.push(value)
    })
  })

  delete datasetsObj.total

  labels = [...new Set(labels)]
  const datasets = Object.values(datasetsObj)

  return {
    labels,
    datasets,
  }
}
